<template>
    <div class="section">
        <div class="container">
            <o-loading full-page :active="loading"></o-loading>
            <div v-show="!loading">
                <h1 class="has-text-centered">
                    You have successfully logged out.
                </h1>
            </div>
        </div>
    </div>
    <!--<div class="section">
        <div class="container">
            <h2 class="title is-2 is-uppercase">Logged out</h2>
            <hr />
            <p>
                You've been logged out.
            </p>
        </div>
    </div>-->
</template>
<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const loading = ref(true);

            onMounted(async () => {
                await $http.get("/api/user/logout");
                global.$store.dispatch("logout");
                loading.value = false;

                //global.$router.push("rfq");
            });

            return {
                loading
            }
        }
    }
</script>